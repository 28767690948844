var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.canLogin)?_c('div',{staticClass:"transfer-naira"},[_c('div',{},[_c('img',{staticClass:"sterling-icon-holder",class:_vm.loading ? 'rotate' : '',attrs:{"src":require("../../assets/images/e-naira-wallet-icon.png"),"alt":"sterling-icon"}})]),_c('div',{staticClass:"login-type"},[_c('button',{staticClass:"enaira-login-type",class:_vm.form.type == 'commerce' ? 'active-enaira-btn' : '',on:{"click":function($event){_vm.form.type = 'commerce'}}},[_vm._v(" Commerce ")]),_c('button',{staticClass:"enaira-login-type",class:_vm.form.type == 'merchant' ? 'active-enaira-btn' : '',on:{"click":function($event){_vm.form.type = 'merchant'}}},[_vm._v(" Merchant ")])]),(_vm.form.type == 'commerce')?_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('div',{staticClass:"form__group--three"},[_c('label',{staticClass:"form__label",attrs:{"for":"email"}}),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v.form.email.$model),expression:"$v.form.email.$model",modifiers:{"lazy":true}}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.email.$error,
          },attrs:{"type":"text","id":"email","name":"email","placeholder":"Email"},domProps:{"value":(_vm.$v.form.email.$model)},on:{"change":function($event){return _vm.$set(_vm.$v.form.email, "$model", $event.target.value)}}}),(_vm.submitted && !_vm.$v.validations.emasil.required)?_c('small',{staticClass:"error"},[_vm._v("Email address is required")]):_vm._e()]),_c('div',{staticClass:"form__group--three"},[_c('label',{staticClass:"form__label",attrs:{"for":"password"}}),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v.form.password.$model),expression:"$v.form.password.$model",modifiers:{"lazy":true}}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
          },attrs:{"type":_vm.visiblePassword ? 'text' : 'password',"id":"password","name":"password","placeholder":"Password"},domProps:{"value":(_vm.$v.form.password.$model)},on:{"change":function($event){return _vm.$set(_vm.$v.form.password, "$model", $event.target.value)}}}),_c('div',{staticClass:"visible-password",on:{"click":function($event){_vm.visiblePassword = !_vm.visiblePassword}}},[(!_vm.visiblePassword)?_c('i',{staticClass:"fas fa-eye-slash"}):_c('i',{staticClass:"fas fa-eye"})]),(_vm.submitted && !_vm.$v.validations.password.required)?_c('small',{staticClass:"error"},[_vm._v("Password is required")]):_vm._e()]),_c('button',{staticClass:"proceed-btn"},[(_vm.loading)?_c('loading'):_vm._e(),_c('span',[_vm._v("Login")])],1)]):_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('div',{staticClass:"form__group--three"},[_c('label',{staticClass:"form__label",attrs:{"for":"username"}}),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v.form.username.$model),expression:"$v.form.username.$model",modifiers:{"lazy":true}}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.username.$error,
          },attrs:{"type":"text","id":"username","name":"username","placeholder":"Username","autocorrect":"off","spellcheck":"false","autocomplete":"off"},domProps:{"value":(_vm.$v.form.username.$model)},on:{"change":function($event){return _vm.$set(_vm.$v.form.username, "$model", $event.target.value)}}}),(_vm.submitted && !_vm.$v.validations.username.required)?_c('small',{staticClass:"error"},[_vm._v("Username is required")]):_vm._e()]),_c('div',{staticClass:"form__group--three"},[_c('label',{staticClass:"form__label",attrs:{"for":"password"}}),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v.form.password.$model),expression:"$v.form.password.$model",modifiers:{"lazy":true}}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
          },attrs:{"type":_vm.visiblePassword ? 'text' : 'password',"id":"password","name":"password","placeholder":"Password"},domProps:{"value":(_vm.$v.form.password.$model)},on:{"change":function($event){return _vm.$set(_vm.$v.form.password, "$model", $event.target.value)}}}),_c('div',{staticClass:"visible-password",on:{"click":function($event){_vm.visiblePassword = !_vm.visiblePassword}}},[(!_vm.visiblePassword)?_c('i',{staticClass:"fas fa-eye-slash"}):_c('i',{staticClass:"fas fa-eye"})]),(_vm.submitted && !_vm.$v.validations.password.required)?_c('small',{staticClass:"error"},[_vm._v("Password is required")]):_vm._e()]),_c('button',{staticClass:"proceed-btn"},[(_vm.loading)?_c('loading'):_vm._e(),_c('span',[_vm._v("Login")])],1)])]):_c('div',{staticClass:"cant-login"},[_c('p',{staticClass:"cant-login-text"},[_vm._v(" You only have a wallet account, you need a primary account to use eNaira. ")]),_c('div',{staticClass:"btn-holder"},[_c('button',{staticClass:"start-btn",on:{"click":function($event){return _vm.$router.push('/account-opening')}}},[_vm._v(" Account Opening ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }