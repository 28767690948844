<template>
  <section>
    <div v-if="canLogin" class="transfer-naira">
      <div class="">
        <img
          src="../../assets/images/e-naira-wallet-icon.png"
          alt="sterling-icon"
          class="sterling-icon-holder"
          :class="loading ? 'rotate' : ''"
        />
      </div>
      <div class="login-type">
        <button
          class="enaira-login-type"
          :class="form.type == 'commerce' ? 'active-enaira-btn' : ''"
          @click="form.type = 'commerce'"
        >
          Commerce
        </button>
        <button
          class="enaira-login-type"
          @click="form.type = 'merchant'"
          :class="form.type == 'merchant' ? 'active-enaira-btn' : ''"
        >
          Merchant
        </button>
      </div>
      <form v-if="form.type == 'commerce'" class="form" @submit.prevent="login">
        <div class="form__group--three">
          <label for="email" class="form__label"> </label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder="Email"
            class="form__input"
            v-model.lazy="$v.form.email.$model"
            :class="{
              'is-invalid': submitted && $v.form.email.$error,
            }"
          />
          <small class="error" v-if="submitted && !$v.validations.emasil.required"
            >Email address is required</small
          >
        </div>
        <div class="form__group--three">
          <label for="password" class="form__label"> </label>
          <input
            :type="visiblePassword ? 'text' : 'password'"
            id="password"
            name="password"
            placeholder="Password"
            class="form__input"
            v-model.lazy="$v.form.password.$model"
            :class="{
              'is-invalid': submitted && $v.form.password.$error,
            }"
          />
          <div class="visible-password" @click="visiblePassword = !visiblePassword">
            <i v-if="!visiblePassword" class="fas fa-eye-slash"></i>
            <i v-else class="fas fa-eye"></i>
          </div>
          <small class="error" v-if="submitted && !$v.validations.password.required"
            >Password is required</small
          >
        </div>
        <button class="proceed-btn">
          <loading v-if="loading" />
          <span>Login</span>
        </button>
      </form>
      <form v-else class="form" @submit.prevent="login">
        <div class="form__group--three">
          <label for="username" class="form__label"> </label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Username"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model.lazy="$v.form.username.$model"
            :class="{
              'is-invalid': submitted && $v.form.username.$error,
            }"
          />
          <small class="error" v-if="submitted && !$v.validations.username.required"
            >Username is required</small
          >
        </div>
        <div class="form__group--three">
          <label for="password" class="form__label"> </label>
          <input
            :type="visiblePassword ? 'text' : 'password'"
            id="password"
            name="password"
            placeholder="Password"
            class="form__input"
            v-model.lazy="$v.form.password.$model"
            :class="{
              'is-invalid': submitted && $v.form.password.$error,
            }"
          />
          <div class="visible-password" @click="visiblePassword = !visiblePassword">
            <i v-if="!visiblePassword" class="fas fa-eye-slash"></i>
            <i v-else class="fas fa-eye"></i>
          </div>
          <small class="error" v-if="submitted && !$v.validations.password.required"
            >Password is required</small
          >
        </div>
        <button class="proceed-btn">
          <loading v-if="loading" />
          <span>Login</span>
        </button>
      </form>
    </div>

    <div v-else class="cant-login">
      <p class="cant-login-text">
        You only have a wallet account, you need a primary account to use eNaira.
      </p>
      <div class="btn-holder">
        <button class="start-btn" @click="$router.push('/account-opening')">
          Account Opening
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import api from "@/api/api.js";

export default {
  data() {
    return {
      submitted: false,
      form: {
        username: "",
        email: "",
        password: "",
        type: "commerce",
      },
      loading: false,
      visiblePassword: false,
      canLogin: true,
    };
  },
  validations: {
    form: {
      email: { required },
      password: { required },
      username: { required },
    },
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      this.submitted = true;
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }
      this.login();
    },
    login() {
      if (this.form.type == "commerce") {
        let payload = {
          email: this.form.email,
          password: this.form.password,
        };
        this.loading = true;
        api
          .loginEnaira(payload)
          .then((response) => {
            this.loading = false;
            let responseMessage = response.Message;
            if (responseMessage === "Request Processed Successfully") {
              let data = {
                enairaUser: response.Data,
                accountType: this.form.type,
              };

              this.$store.commit("setEnairaUserData", data);
              this.$router.push({
                name: "E-naira Transactions",
              });
            } else {
              this.$message({
                showClose: true,
                message: `${
                  responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                }`,
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$message({
              showClose: true,
              message: `Error logging please try again`,
              type: "error",
              duration: 10000,
            });
            throw error;
          });
      } else {
        let payload = {
          username: this.form.username,
          password: this.form.password,
        };
        this.loading = true;
        api
          .eNairaMerchantLogin(payload)
          .then((response) => {
            this.loading = false;
            let responseMessage = response.Message;
            if (responseMessage == "Request Processed Successfully") {
              let data = {
                enairaUser: response.Data,
                accountType: this.form.type,
              };
              this.$store.commit("setEnairaUserData", data);
              this.$router.push({
                name: "E-naira Transactions",
              });
            } else {
              this.$message({
                showClose: true,
                message: `${
                  responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                }`,
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$message({
              showClose: true,
              message: `Error logging please try again`,
              type: "error",
              duration: 10000,
            });
            throw error;
          });
      }
    },
  },
  mounted() {
    if (this.$store.state.user.profileInfo.PryAccount == "") {
      this.canLogin = false;
    } else this.canLogin = true;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
.service-offer {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: #000000;
}
.service-list {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 4em;
  color: #606062;
  list-style: none;
  padding-top: 2em;
}
.service-list li::before {
  content: "\2022";
  color: #66cc15;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 15px;
}
.pta-select {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  widows: 100%;
}
#open-sterling {
  color: #ffffff;
  background: #db353a;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px;
}
.non-sterling {
  margin-right: auto;
}
.proceed-btn {
  color: #ffffff;
  background: linear-gradient(180deg, #013d27 0%, #00492e 100%);
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 1.2rem 3.8rem;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;
}
.transfer-naira {
  max-width: 70rem;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  margin: 12rem auto;
  padding: 5rem;
}

.enaira-login-type {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.4rem;
  text-align: center;
  letter-spacing: 0.05em;
  display: inline-block;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  padding: 1em 2em;
  flex: 1;
}

.active-enaira-btn {
  color: #ffffff;
  background: linear-gradient(180deg, #013d27 0%, #00492e 100%);
  transition: all 0.3s;
}

.sterling-icon-holder {
  display: block;
  margin: 0 auto 4rem;
}

.btn-holder {
  width: 80%;
  text-align: center;
  margin: 0 auto;
  margin-top: 3rem;
}

.start-btn {
  background: linear-gradient(180deg, #013d27 0%, #00492e 100%);
  border-radius: 7px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 1.2rem 1.5rem;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  color: #fff;
}

.cant-login-text {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 400;
  font-size: 2.1rem;
  line-height: 2.8rem;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 3rem;
}
.cant-login {
  margin: 10rem 2rem;
}

.coming-soon {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 0.5em 1em;
}

.visible-password {
  float: right;
  margin-top: -35px;
  padding: 0 6px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 600px) {
  .enaira-login-type {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;
    letter-spacing: 0.05em;
    display: inline-block;
    text-decoration: none;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    padding: 0.5em 1em;
    flex: 1;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
}
@media only screen and (min-width: 768px) {
}
</style>
